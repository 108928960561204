import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


import Dashboard from './views/Dashboard';
import SignIn from "./views/SignIn";
import SignUp from "./views/SignUp";

function App() {
  return (
    <div className="bg-[#ffffff] dark:bg-[#1c1c1c]">
      <Router>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
