import React, { useState, useEffect } from 'react';
import Navbar from "../components/layout/Navbar";

function Dashboard() {
  
  const [notice, setNotice] = useState(true);

  useEffect(() => {

  });


  return (
    <div className="min-h-screen ">
      <Navbar />
      <div className="w-full sm:w-4/5 md:w-3/5 m-auto">
      <div className="bg-gray rounded-md py-4 sm:py-8 md:py-16 px-4 " >
        {notice && (<div className="color-white py-4  md:py-10 px-4 md:px-16 bg-black rounded-lg" style={{height: '250px', backgroundSize:'cover', backgroundImage: "url('../img/dashboard/slide-bg.png')"}}>
          <div className="">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white  font-bold float-right cursor-pointer"
             onClick={() => { setNotice(false) }} 
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
          <div>
            <p className="text-2xl lg:text-3xl xl:text-4xl text-white font-bold mt-12 md:mt-6">Create something magical</p>
            <p className="text-lg lg:text-xl mt-3 text-white">Supercharge your copy with our AI-powered templates.</p>
          </div>
        </div>)}
      </div>
      <div className="p-16 bg-blue bg-[#f8f9ff] rounded-lg">
        <div className="">
          <div className="flex justify-between items-center">
            <p className="font-bold">Select an AI-powered template</p>
            <a className="text-[#6e57ff] font-bold text-sm cursor-pointer">
              Looking for something else?
            </a>
          </div>
          <div class="mt-8 grid grid-cols-1 gap-1 md:grid-cols-2 md:gap-2 2xl:grid-cols-4 xl:gap-4">
            <div className="border p-10 rounded-md bg-[#6e57ff] text-white h-44 text-center cursor-pointer flex flex-col justify-between shadow-xl mt-4 md:hover:mt-0 transition-all">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10 h-10 text-center  m-auto"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <p>New Doc</p>
            </div>
            <div className="border p-4 rounded-md bg-white h-44 cursor-pointer flex flex-col justify-between shadow-xl mt-4 md:hover:mt-0 transition-all">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
              </svg>
              <div>
                <p className="font-semibold">Blog post</p>
                <p className="text-sm">Create a blog post within minutes</p>
              </div>
            </div>
            <div className="border p-4 rounded-md bg-white h-44 flex flex-col justify-between shadow-xl mt-4 md:hover:mt-0 transition-all">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
              </svg>
              <div>
                <p className="text-[#6e57ff]  font-semibold"><small>Coming Soon</small></p>
                <p className="font-semibold">Product description</p>
                <p className="text-sm">Create a product description within minutes</p>
              </div>
            </div>
            <div className="border p-4 rounded-md bg-white h-44  flex flex-col justify-between shadow-xl mt-4 md:hover:mt-0 transition-all">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
              </svg>
              <div>
              <p className="text-[#6e57ff]  font-semibold"><small>Coming Soon</small></p>
                <p className="font-semibold">Landing page</p>
                <p className="text-sm">Create a landing page within minutes</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-16">
        <div className="">
          <p className="font-bold">Your documents</p>
          <table class="table-auto w-full mt-4 text-left p-2 a-table">
            <thead className="">
              <tr className="">
                <th className="">Title</th>
                <th>Last modified</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b ">
                <td>The Sliding Mr. Bones</td>
                <td>Malcolm Lockyer</td>
                <td>1961</td>
              </tr>
              <tr className="border-b">
                <td>Witchy Woman</td>
                <td>The Eagles</td>
                <td>1972</td>
              </tr>
              <tr className="border-b">
                <td>Shining Star</td>
                <td>Earth, Wind, and Fire</td>
                <td>1975</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Dashboard;
