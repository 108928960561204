import React from 'react';
import { Link } from 'react-router-dom';

function SignUp() {
  


  return (
    <div className="min-h-screen ">
      <div
        className="area justify-center items-center bg-orange- via-orange-700 to-orange-800 bg-[#7150e9]"
        style={{
          position: "absolute",
        }}
      >
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className="flex justify-center items-center h-screen">
          <div
            className="shadow-2xl w-5/6 md:w-1/2 xl:w-1/3 2xl:w-1/4 bg-transparent rounded-xl p-10 m-auto bg-[#e7ecee] z-40"
            style={{
              minHeight: "500px",
            }}
          >
            <p className='text-2xl md:text-3xl text-center font-bold'>HipTyper.ai</p>
            <p className='mt-4 mb-4 text-lg md:text-xl text-center'>Create your free account</p>
            <p className='text-center px-4'><small>By creating an account, you agree to the Terms of Service and Privacy Policy</small></p>
            <div className='p-4'>
            <a href="#_" className="border rounded-full text-center m-auto inline-flex items-center justify-center px-8 py-2 w-full  border- text-base font-medium leading-6 text-gray-600 whitespace-no-wrap bg-white border border-gray-200 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:shadow-none">
            Continue with Google
            </a>
            <hr className='mt-4 mb-4' style={{color: 'black', backgroundColor: 'black', borderColor: 'darkgray'}}/>
            <input className="text-purple w-full p-3 mt-2 rounded-md focus:border-lightgray" placeholder="Email Address" />
            <input className="text-purple w-full p-3 mt-2 rounded-md" placeholder="Password" />
            
            
            <div className='mt-6'>
             <Link to={'/dashboard'} class="inline-flex items-center justify-center px-8 py-2 w-full text-center m-auto text-base font-bold leading-6 text-white bg-indigo-600 border border-transparent rounded-full  hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600">
              Sign Up
              </Link>
            </div>  
            <p className='mt-4'>Already have an account?  <Link to={'/signIn'} className="ml-2 text-[#6e57ff] font-bold text-sm cursor-pointer">
              Log In
            </Link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
